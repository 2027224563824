import request from "./request";
export const categoryApi = {

    //获取分类列表
    async getCategories(){
      const res = await request.get('/categories');
      return res.data.success ? res.data.data : res.data.message;
    },

    //根据id获取分类
    async getCategory(id){
        const res = await request.get(`/categories/${id}`);
        return res.data.success ? res.data.data : res.data.message;
    },

    //添加分类
    async createCategory(category){
        const res = await request.post('/categories',category);
        return res.data.success ? res.data.data : res.data.message;
    },

    //修改分类
    async updateCategory(id,category){
        const res = await request.put(`/categories/${id}`,category);
        return res.data;
    },

    //删除分类
    async deleteCategory(id){
        const res = await request.delete(`/categories/${id}`);
        return res.data;
    }


}