<template>
  <div id="app">
    <RouterView></RouterView>
  </div>
</template>

<script>
export default {
  
}
</script>

<style>

</style>
