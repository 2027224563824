import axios from "axios";
import { ElMessage } from "element-plus";
import router from '../router/router'

const request = axios.create({
    baseURL: 'https://qs233.cn',
    timeout: 300000
})

//request 请求拦截器
request.interceptors.request.use(config=>{
    config.headers['Content-Type'] = 'application/json;charset=utf-8';
    let token = JSON.parse(localStorage.getItem("token"));
    config.headers['token'] = token;

    return config;
},error=>{
    return Promise.reject(error)
});

// request 响应拦截器
request.interceptors.response.use(
    (response) => {
        if (response.data.message === "NOTLOGIN") {
            ElMessage.error("请登录");
            // 重定向到登录页面
            router.push('/login');
            return Promise.reject('未登录'); // 确保返回拒绝的 Promise
        }
        return response;
    },
    (error) => {
        // 统一处理错误响应
        ElMessage.error('请求失败，请稍后再试');
        return Promise.reject(error);
    }
);


export default request;