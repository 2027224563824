<template>
    <div class="post-page">
        <el-card style="width: 100%;">
            <template #header>
                <div class="card-header">
                    <span>文章列表</span>
                </div>
            </template>
            <el-button type="primary" plain @click="createPost()">添加文章</el-button>
            <el-table :data="postList" :border="true" style="width: 100%;margin-top: 30px">
                <el-table-column prop="title" label="标题" />
                <el-table-column prop="author" label="作者" />
                <el-table-column prop="content" label="内容" show-overflow-tooltip>
                    <template v-slot="scope">
                        <el-button @click="showContent(scope.row.content)">显示内容</el-button>
                    </template>
                </el-table-column>
                <el-table-column prop=""></el-table-column>
                <el-table-column prop="createdAt" label="创建时间" />
                <el-table-column label="操作">
                    <template v-slot="scope">
                        <el-button type="success" plain @click="updatePost(scope.row)">修改文章</el-button>
                        <el-button type="danger" plain @click="deletePost(scope.row.id)">删除文章</el-button>
                        <el-button type="success" plain @click="viewComment(scope.row)">查看评论</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-card>
    </div>


    <el-dialog v-model="opPostFormVisible" :title="opPost" width="70%" @close="cancle()">
        <el-form :model="form" :rules="opPostFormRule" ref="opPostFormRef">
            <el-form-item label="标题" prop="title" :label-width="formLabelWidth">
                <el-input v-model="form.title" autocomplete="off" />
            </el-form-item>
            <el-form-item label="内容" prop="content" :label-width="formLabelWidth">
                <div id="editor"></div>
            </el-form-item>
        </el-form>
        <template #footer>
            <div class="dialog-footer">
                <el-button @click="cancle()">取消</el-button>
                <el-button type="primary" @click="submitForm()">
                    确认
                </el-button>
            </div>
        </template>
    </el-dialog>

    <el-dialog v-model="contentVisible" title="内容" width="60%">
        <el-card>
            <div v-html="content"></div>
        </el-card>
    </el-dialog>

    <el-dialog v-model="commentVisible" title="评论" width="60%">

    </el-dialog>

</template>
<script>
import { postApi } from '@/js/blogPost';
import { ElMessageBox } from 'element-plus';
import E from "wangeditor"; //富文本编辑器
import hljs from 'highlight.js'; //代码高亮
import { commentApi } from '@/js/blogComment';

export default {
    data() {
        return {

            categories:[],
            postList: [],
            form: {
                id: 0,
                title: '',
                content: '',
            },
            formLabelWidth: '100px',
            opPostFormVisible: false,
            opPost: '',
            opPostFormRule: {
                title: [
                    { required: true, message: '请输入标题', trigger: 'blur' },
                ],
            },
            editor: null,//富文本编辑器对象

            contentVisible:false,
            content:'',
            allComments:[],
            commentVisible:false,
        }
    },
    methods: {

        async getPosts() {
            this.postList = await postApi.getPosts();
        },

        createPost() {
            this.opPostFormVisible = true;
            this.opPost = '添加文章';

            //初始化富文本编辑器
            this.$nextTick( () =>{
                this.editor = new E('#editor');
                this.editor.highlight = hljs;
                this.editor.create();
               
            })
           
        },

        updatePost(row) {
            this.form = JSON.parse(JSON.stringify(row));  // 深拷贝当前行的数据到 formData，避免直接引用
            this.opPostFormVisible = true;
            this.opPost = '修改文章';
            this.$nextTick(()=>{
                this.editor = new E('#editor');
                this.editor.highlight = hljs;
                this.editor.create();
                this.editor.txt.html(row.content);
            })
            
        },

        deletePost(id) {
            ElMessageBox.confirm('您确定要删除该文章吗?', '警告', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                postApi.deletePost(id).then(() => { this.getPosts() })
                this.$message.success('删除成功!');
            }).catch(() => {
                this.$message.info('删除已取消');
            });
        },

        submitForm() {
            this.$refs.opPostFormRef.validate((valid) => {
                if (!valid) return;

                let content = this.editor.txt.html();
                this.form.content = content;
                if (this.opPost === '添加文章') {
                    postApi.createPost(this.form).then(() => { this.getPosts() });
                    this.$message.success("添加成功!");
                }
                if (this.opPost === '修改文章') {
                    postApi.updatePost(this.form.id, this.form).then(() => { this.getPosts() });
                    this.$message.success("更新成功!");
                }
                this.opPostFormVisible = false;
                this.resetForm();
            })
        },

        viewComment(row){
            this.commentVisible = true;
            commentApi.getComments(row.id).then((res)=>{
                if(res.success){
                    this.allComments = res.data;
                }
            })
        },

        cancle() {
            if(this.editor){
                this.editor.destroy();
                this.editor = null;
            }
            this.opPostFormVisible = false;
            this.resetForm();
        },

        resetForm() {
            this.$refs.opPostFormRef.resetFields();
            this.form = { title: '', }
        },

        showContent(content){
            this.content = content;
            this.contentVisible = true; 
        }
    },
    created() {
        this.getPosts()
    }
}
</script>
<style>
.post-page{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: start;
    align-items: start;
}
</style>