<template>
    <div class="main">
        <div class="login">
            <h2>Login</h2>
            <el-form ref="loginFormRef" :model="LoginForm" label-position="left">
                <el-form-item>
                    <label>账号</label>
                    <el-input class="loginInput" v-model="LoginForm.username" />
                </el-form-item>
                <el-form-item>
                    <label>密码</label>
                    <el-input class="loginInput" type="password" v-model="LoginForm.password" />
                </el-form-item>
            </el-form>
            <div class="btnBox">
                <el-button type="primary" plain :onclick="login">登录</el-button>
                <el-button type="primary" plain :onclick="login">注册</el-button>
            </div>
        </div>
    </div>
</template>

<script>
import request from '@/js/request';

export default {
    data() {
        return {
            LoginForm: { username: '', password: '' }
        };
    },
    methods: {
        async login() {
            const res = await request.post('/users/login', this.LoginForm);
            if(!res.data.success){
                return this.$message.error(res.data.message);
            }
            this.$message.success("欢迎访问博客后台");
            localStorage.setItem("token",JSON.stringify(res.data.data));
            this.$router.push('/home')
        },
    },
}

</script>
<style>
body{
    margin: 0;
}
.main {
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: url('../../public/image/1.jpg') no-repeat;
    background-size: cover;
}

.login {
    border-radius: 20px;
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 350px;
    height: 380px;
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    border-left: 1px solid rgba(255, 255, 255, 0.5);
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    border-right: 1px solid rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(10px);
}

.login h2 {
    color: #fff;
    margin-bottom: 0;
}

.login el-form-item {
    width: 250px !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
}

.login label {
    font-size: 13px;
    color: rgba(255, 255, 255, 0.9);
}

.login .el-form-item__content{
    width: 250px;
}
.login .el-input__wrapper {
    font-size: 14px;
    height: 35px;
    background: rgba(255, 255, 255, 0.3) !important;
}

.login .el-input__wrapper:hover {
    box-shadow: none;
}

.login .el-input__inner {
    color: rgba(255, 255, 255, 0.9) !important;
    outline: none;
    transition: 0.2s;
}

.login .is-focus {
    box-shadow: none !important;
    border: 1px solid rgba(255, 255, 255, 0.8);
}

.btnBox button {
    width: 120px;
    border: 1px solid rgba(197,81,58,0.8);
    background: rgba(197,81,58,0.5);
    color: rgba(255,255,255,0.9);
    transition: 0.2s;
}
.btnBox button:hover {
    border: 1px solid rgba(248,108,76,0.8);
    background: rgba(248,108,76,0.5);
}
.btnBox {
    margin-top: 10px;
    flex-direction: row;
    justify-content: center;
    align-items: start;
    display: flex;
}
</style>