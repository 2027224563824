<template>
    <div class="category-page">
        <el-card style="width: 100%;">
            <template #header>
                <div class="card-header">
                    <span>分类列表</span>
                </div>
            </template>
            <el-button type="primary" plain @click="createCategory">添加分类</el-button>
            <el-table :data="categoryList" :border="true" style="width: 100%;margin-top: 30px">
                <el-table-column prop="name" label="分类名" />
                <el-table-column label="操作">
                    <template v-slot="scope">
                        <el-button type="success" plain @click="updateCategory(scope.row)">修改分类</el-button>
                        <el-button type="danger" plain @click="deleteCategory(scope.row.id)">删除分类</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-card>
    </div>

    <el-dialog v-model="opCategoryFormVisible" :title="opCategory" width="70%" @close="cancle()">
        <el-card>
            <el-form :model="form" :rules="opCategoryFormRule" ref="opCategoryFormRef">
                <el-form-item label="分类名称" prop="name" :label-width="formLabelWidth">
                    <el-input v-model="form.name" />
                </el-form-item>
            </el-form>
            <template #footer>
                <div class="dialog-footer">
                    <el-button @click="cancle()">取消</el-button>
                    <el-button type="primary" @click="submitForm()">
                        确认
                    </el-button>
                </div>
            </template>
        </el-card>
    </el-dialog>

</template>
<script>
import { categoryApi } from '@/js/blogCategory';
import { ElMessageBox } from 'element-plus';
export default {
    data() {
        return {
            categoryList: [],
            opCategoryFormVisible: false,
            formLabelWidth: '100px',
            opCategory: '',
            form: {
                id: '',
                name: ''
            },
            opCategoryFormRule: {
                name: [
                    { required: true, message: '请输入分类名', trigger: 'blur' },
                ],
            },
        }
    },
    methods: {
        async getCategories() {
            this.categoryList = await categoryApi.getCategories()
        },
        createCategory() {
            this.opCategoryFormVisible = true;
            this.opCategory = "添加分类";
        },
        updateCategory(row) {
            this.form = JSON.parse(JSON.stringify(row));
            this.opCategoryFormVisible = true;
            this.opCategory = "修改分类";
        },

        deleteCategory(id) {
            ElMessageBox.confirm('您确定要删除该分类吗?', '警告', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                categoryApi.deleteCategory(id).then((res) => {
                    if(!res.success){
                        this.$message.warning(res.message);
                    }else{
                        this.getCategories();
                        this.$message.success('删除成功!');
                    } 
                }) 
            }).catch(() => {
                this.$message.info('删除已取消');
            });
        },

        submitForm() {
            this.$refs.opCategoryFormRef.validate((valid) => {
                if (!valid) return;
                if (this.opCategory == "添加分类") {
                    categoryApi.createCategory(this.form).then(() => {
                        this.getCategories();
                        this.$message.success("添加成功");
                    });
                }
                if (this.opCategory == "修改分类") {
                    categoryApi.updateCategory(this.form.id, this.form).then((res) => {
                        if (!res.success) {
                            this.$message.warning(res.message);
                        } else {
                            this.getCategories();
                            this.$message.success("修改成功");
                        }

                    });
                }
                this.opCategoryFormVisible = false;
                this.resetForm();
            })

        },

        cancle() {
            this.opCategoryFormVisible = false;
            this.resetForm();
        },

        resetForm() {
            this.$refs.opCategoryFormRef.resetFields();
            this.form = { name: '', }
        },
    },
    created() {
        this.getCategories()
    }
}
</script>
<style>
.category-page {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: start;
    align-items: start;
}
</style>