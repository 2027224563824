// src/main.js
import { createApp } from 'vue';
import App from './App.vue';
import router from './router/router';
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css'; // 引入 Element Plus 的样式文件
import 'highlight.js/styles/monokai-sublime.css';

const app = createApp(App);
app.use(ElementPlus);
app.use(router);
app.mount('#app');


//下面这段代码用来解决控制台开关时出现 ResizeObserver loop completed with undelivered notifications 错误
const debounce = (fn, delay) => {
    let timer
     return (...args) => {
       if (timer) {
         clearTimeout(timer)
       }
       timer = setTimeout(() => {
         fn(...args)
       }, delay)
     }
  }
    
  const _ResizeObserver = window.ResizeObserver;
  window.ResizeObserver = class ResizeObserver extends _ResizeObserver{
     constructor(callback) {
       callback = debounce(callback, 200);
       super(callback);
     }
  }





