<template>
    <div class="comment-page">
        <el-card style="width: 100%;">
            <template #header>
                <div class="card-header">
                    <span>评论列表</span>
                </div>
            </template>
            <el-button type="primary" plain>添加评论</el-button>
            <el-table :data="commentList" :border="true" style="width: 100%;margin-top: 30px">
                <el-table-column prop="commentname" label="评论名" />
                <el-table-column prop="email" label="邮箱" />
                <el-table-column prop="createdAt" label="创建时间" />
                <el-table-column label="操作">
                    <template v-slot="">
                        <el-button type="success" plain>修改评论</el-button>
                        <el-button type="danger" plain>删除评论</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-card>
    </div>
</template>
<script>
import { commentApi } from '@/js/blogComment';
export default {
    data() {
        return {
            postId:1,
            commentList:[]
        }
    },
    methods: {
        async getComments(){
            this.commentList = await commentApi.getComments(this.postId);
        }
    },
    created() {
        this.getComments()
    }
}
</script>
<style>
.comment-page{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: start;
    align-items: start;
}
</style>