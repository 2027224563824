import request from "./request";
export const postApi = {

    //获取文章列表
    async getPosts(){
      const res = await request.get('/posts');
      return res.data.success ? res.data.data : res.data.message;
    },

    //根据id获取文章
    async getPost(id){
        const res = await request.get(`/posts/${id}`);
        return res.data.success ? res.data.data : res.data.message;
    },

    //添加文章
    async createPost(post){
        const res = await request.post('/posts',post);
        return res.data.success ? res.data.data : res.data.message;
    },

    //修改文章
    async updatePost(id,post){
        const res = await request.put(`/posts/${id}`,post);
        return res.data.success ? res.data.data : res.data.message;
    },

    //删除文章
    async deletePost(id){
        const res = await request.delete(`/posts/${id}`);
        return res.data.success ? res.data.data : res.data.message;
    }
}