import request from "./request";
export const commentApi = {

    //获取某个文章下的评论列表
    async getComments(postId){
      const res = await request.get(`/comments/post/${postId}`);
      return res.data.data;
    },

    //添加评论
    async createComment(comment){
        const res = await request.post('/comments',comment);
        return res.data.success ? res.data.data : res.data.message;
    },

    //删除评论
    async deleteComment(id){
        const res = await request.delete(`/comments${id}`);
        return res.data.success ? res.data.data : res.data.message;
    }


}