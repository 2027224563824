<template>
  <div class="common-layout">
    <el-container>
      <el-header style="background-color: #ddfaac;text-align: center;align-items: center;justify-content: center;display: flex;font-size: 23px;">博客后台</el-header>
      <el-container>
        <el-aside width="200px" style="background-color: #ddd;height: 90vh;">
          <el-menu class="menu" style="background-color: #ddd;" :router="true">
            <el-menu-item v-for="item in menu" :key="item.index" :index="item.link">
              <span>{{ item.name }}</span>
            </el-menu-item>
          </el-menu>
        </el-aside>
        <el-main><router-view></router-view></el-main>
      </el-container>
    </el-container>
  </div>
</template>
<script>

export default {
  data() {
    return {
        menu:[
          {
            index:1,
            name:"用户",
            link:"/user"
          },
          {
            index:2,
            name:"文章",
            link:"/post"
          },
          {
            index:3,
            name:"分类",
            link:"/category"
          },
          {
            index:4,
            name:"评论",
            link:"/comment"
          }
        ]
    }
  }
}
</script>