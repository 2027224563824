// src/router/index.js
import { createRouter, createWebHistory } from 'vue-router';
import BlogHome from '../components/BlogHome.vue'
import UserLogin from '../components/UserLogin.vue';
import BlogUser from '@/components/BlogUser.vue';
import BlogPost from '@/components/BlogPost.vue';
import BlogCategory from '@/components/BlogCategory.vue';
import BlogComment from '@/components/BlogComment.vue';
import { ElMessage } from 'element-plus';


const routes = [
  {
    path: '/',
    redirect: 'login'
  },
  {
    path: '/login',
    component: UserLogin
  },
  {
    path: '/home',
    component: BlogHome,
    redirect: 'user',
    meta: { requiresAuth: true },  // 自定义meta字段
    children: [
      {
        path: '/user',
        component: BlogUser,
        meta: { requiresAuth: true }  // 自定义meta字段
      },
      {
        path: '/post',
        component: BlogPost,
        meta: { requiresAuth: true }  // 自定义meta字段
      },
      {
        path: '/category',
        component: BlogCategory,
        meta: { requiresAuth: true }  // 自定义meta字段
      },
      {
        path: '/comment',
        component: BlogComment,
        meta: { requiresAuth: true }  // 自定义meta字段
      }
    ]
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

// 全局前置守卫
router.beforeEach((to, from, next) => {
  const token = localStorage.getItem('token');

  if (to.path === '/login') { 
    localStorage.removeItem("token");
    return next() 
  }

  if (token === null) {
    ElMessage.warning("登录信息过期，请重新登录。");
    return next('/login');
  }

  next();

});

export default router;
