<template>
    <div class="user-page">
        <el-card style="width: 100%;">
            <template #header>
                <div class="card-header">
                    <span>用户列表</span>
                </div>
            </template>
            <el-button type="primary" plain @click="createUser()">添加用户</el-button>
            <el-table :data="userList" :border="true" style="width: 100%;margin-top: 30px">
                <el-table-column prop="username" label="用户名" />
                <el-table-column prop="email" label="邮箱" />
                <el-table-column prop="createdAt" label="创建时间" />
                <el-table-column label="操作">
                    <template v-slot="scope">
                        <el-button type="success" plain @click="updateUser(scope.row)">修改用户</el-button>
                        <el-button type="danger" plain @click="deleteUser(scope.row.id)">删除用户</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-card>
    </div>


    <el-dialog v-model="opUserFormVisible" :title="opUser" width="40%" >
        <el-form :model="form" :rules="opUserFormRule" ref="opUserFormRef">
            <el-form-item label="用户名" prop="username" :label-width="formLabelWidth">
                <el-input v-model="form.username" autocomplete="off" :disabled="usernameDisabled" />
            </el-form-item>
            <el-form-item label="密码" prop="password" :label-width="formLabelWidth">
                <el-input v-model="form.password" autocomplete="off" />
            </el-form-item>
            <el-form-item label="邮箱" prop="email" :label-width="formLabelWidth">
                <el-input v-model="form.email" autocomplete="off" />
            </el-form-item>
        </el-form>
        <template #footer>
            <div class="dialog-footer">
                <el-button @click="cancle()">取消</el-button>
                <el-button type="primary" @click="submitForm()">
                    确认
                </el-button>
            </div>
        </template>
    </el-dialog>

</template>
<script>
import { userApi } from '@/js/blogUser';
import { ElMessageBox } from 'element-plus';
export default {
    data() {
        return {
            userList: [],
            form: {
                id: 0,
                username: '',
                password: '',
                email: '',
                createdAt: ''
            },
            formLabelWidth: '200px',
            opUserFormVisible: false,
            opUser: '',
            usernameDisabled: false,
            opUserFormRule: {
                username: [
                    { required: true, message: '请输入用户名', trigger: 'blur' },
                    { min: 3, max: 10, message: "长度在3~10个字符之间", trigger: 'blur' }
                ],
                email: [
                    { required: true, message: '请输入邮箱', trigger: 'blur' },
                    { type: "email", message: '请输入正确的邮箱地址', trigger: ['blur', 'change'] },
                ],
                password: [
                    { required: true, message: '请输入密码', trigger: 'blur' },
                    { min: 6, max: 20, message: '密码长度应在 6 到 20 个字符之间', trigger: 'blur' },
                ],
            }
        }
    },
    methods: {
        async getUsers() {
            this.userList = await userApi.getUsers();
        },

        createUser() {
            this.opUserFormVisible = true;
            this.opUser = '添加用户';
        },

        updateUser(row) {
            this.usernameDisabled = true;
            this.form = Object.assign({}, row);  // 深拷贝当前行的数据到 formData，避免直接引用
            this.opUserFormVisible = true;
            this.opUser = '修改用户';

        },

        deleteUser(id) {
            ElMessageBox.confirm('您确定要删除该用户吗?', '警告', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                userApi.deleteUser(id).then(() => { this.getUsers() })
                this.$message.success('删除成功!');
            }).catch(() => {
                this.$message.info('删除已取消');
            });
        },

        submitForm() {
            this.$refs.opUserFormRef.validate((valid) => {
                if (!valid) return;
                if (this.opUser === '添加用户') {
                    userApi.createUser(this.form).then(() => { this.getUsers() });
                    this.$message.success("添加成功!");
                }
                if (this.opUser === '修改用户') {
                    userApi.updateUser(this.form.id, this.form).then(() => { this.getUsers() });
                    this.$message.success("更新成功!");
                }
                this.opUserFormVisible = false;
                this.resetForm();
            })
        },

        cancle() {
            this.opUserFormVisible = false;
            this.resetForm();
        },

        resetForm() {
            this.usernameDisabled = !this.usernameDisabled;
            this.$refs.opUserFormRef.resetFields();
            this.form = { username: '', password: '', email: '' }
        }
    },
    created() {
        this.getUsers()
    }
}
</script>
<style>
.user-page {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: start;
    align-items: start;
}
</style>