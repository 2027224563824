import request from "./request";
export const userApi = {

    //获取用户列表
    async getUsers(){
      const res = await request.get('/users');
      return res.data.success ? res.data.data : res.data.message;
    },

    //根据id获取用户
    async getUser(id){
        const res = await request.get(`/users/${id}`);
        return res.data.success ? res.data.data : res.data.message;
    },

    //添加用户
    async createUser(user){
        const res = await request.post('/users',user);
        return res.data.success ? res.data.data : res.data.message;
    },

    //修改用户
    async updateUser(id,user){
        const res = await request.put(`/users/${id}`,user);
        return res.data.success ? res.data.data : res.data.message;
    },

    //删除用户
    async deleteUser(id){
        const res = await request.delete(`/users/${id}`);
        return res.data.success ? res.data.data : res.data.message;
    }


}